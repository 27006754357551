
.badge-error {
  color: #fff;
  background-color: #de002e !important;
}

.badge-success {
  color: #fff;
  background-color: #9bd732 !important;
}

.badge-notification {
  color: #fff;
  background-color: #4f2d7f;
}