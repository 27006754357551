.subscriptions-edit {
  min-height: 20em;
    #customer, #customer-users #customer-users-2 {
      z-index: 10;
    }
  .subscriptions-edit-matrix {
    transition: all 0.2s ease-in-out;

    .subscriptions-edit-actions {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
}