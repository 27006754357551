@keyframes spin {
  0% { transform: rotate(0deg); }
  100% {  transform: rotate(720deg); }
}

$smallSize: 30;
$mediumSize: 40;
$largeSize: 48;
$xLargeSize: 60;

$defaultSize: $smallSize;

@function pixel($value) {
  @return calc(1px * $value);
}

@function em($value) {
  @return calc(1em * calc($value / 37));
}

.information {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.loader, .message {
  display: inline-flex;

  .loader-container, .message-container {
    width: pixel($defaultSize);
    height: pixel($defaultSize);

    .icon {
      color: #6e4898;
      font-size: pixel($defaultSize);
    }

    &.medium {
      width: pixel($mediumSize);
      height: pixel($mediumSize);
      .icon {
        font-size: pixel($mediumSize);
      }
    }

    &.large {
      width: pixel($largeSize);
      height: pixel($largeSize);
      .icon {
        font-size: pixel($largeSize);
      }
    }

    &.xlarge {
      width: pixel($xLargeSize);
      height: pixel($xLargeSize);
      .icon {
        font-size: pixel($xLargeSize);
      }
    }
  }

  .loader-container {
    .icon::before {
      animation: spin 2s infinite; // linear
      animation-timing-function: cubic-bezier(.43,.84,.61,.14);
    }
  }

  .loader-label, .message-label {
    margin-left: 0.8em;
    margin-top: auto;
    margin-bottom: auto;
    font-size:em($smallSize);

    &.medium {
      font-size:em($mediumSize);
    }
    &.large {
      font-size:em($largeSize);
    }
    &.xlarge {
      font-size:em($xLargeSize);
    }

  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  .loader {
    background:white;
    padding:2rem;
  }
}
