.agenda-items-feed {
  border-style:solid;
  border-color: #C8BEAF;
  border-width: 1px 1px 4px;

  .agenda-items-list {
    .agenda-item-title {
      color:#000000;
      font-size: 18px;
      font-weight: 500;
    }
    .agenda-item-publication-date {
        width:80px;
        line-height:1.5rem;
        color:#4f2d7f;
        .agenda-item-publication-day {
            font-size:1.5rem;
        }
    }
    .agenda-item-description {
      display:flex;
      align-items:center;
      .agenda-item-info {
        flex:1;
        .agenda-item-abstract {
          text-align: justify;
          color:#333333;
          font-size: 16px;
          min-height:4.5rem;
        }
      }
      button {
        margin-left:2rem;
      }
    }
    .agenda-item-tags {
      color:#999999;
      font-size: 12px;
      text-align: right;
    }
  }
  .all-agenda-items {
    a {
      color:#666666;
      font-size:14px;
    }
  }
}
