.content-editor {

  background-color: white;
  border: 1px solid #74767A;

  .ce-block__content {}

  .ce-block__content, .ce-toolbar__content {
    max-width:calc(92% - 80px) !important;
  }
  .cdx-block {
    max-width: 100% !important;
  }

  .ce-toolbar {
    .ce-toolbar__actions {
      padding-left: 0;
      padding-right: 0;
      margin-right: 5px;
    }

    .ce-toolbar__plus:hover, .ce-toolbar__settings-btn:hover {}
  }
}

.cdx-notifies {
  z-index: 10000;
}
