.app-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration:none;
    transition: box-shadow 0.2s ease;
    &:hover {
        box-shadow: 0 0 2.5rem 0 rgba(51, 51, 51, 0.15)
    }
    &.disabled {
        opacity:0.5
    }
    h2 {
        color:#000000;
    }
    .img-project {
        max-height: 150px;
        max-width:  150px;
        margin-bottom:1.5rem;
    }
}