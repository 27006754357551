
.form-title {
  padding-top: 1em;
}

.form {
  position: relative;
  max-width: 100%;
  margin-top: 0.5em;
  border-top: #9681b2 1px solid;

  &.disabled {
    input, button {
      pointer-events: none;
    }
  }

  .fields {
    margin-top: 1em;
    border: none;

    legend {
      font-weight: bold;
    }

    .form-input, .form-select, .form-datepicker, .form-wysiwyg, .form-button-group, .form-radio-group, .form-checkbox-group, > .form-checkbox, > .form-radio {
      padding-top: 0.5em;
      padding-left: 0.5em;
      padding-right: 0.5em;
    }

    .input {
      display: flex;
      position: relative;
      align-items: center;

      input {
        padding-right: 2em;
      }

      .form-input-loader {
        display: inline-flex;
        position: absolute;
        right: 0;
        margin-right:1em;
        animation: spin 2s infinite; // linear
        animation-timing-function: cubic-bezier(.43,.84,.61,.14);
        transition: all .3s ease;
      }
    }

    .form-radio-group-label, .form-checkbox-group-label {
      font-size: 0.875rem;
    }

    .form-radio-group, .form-select, .form-datepicker, .form-wysiwyg, .form-button-group, .form-checkbox-group, > .form-checkbox, > .form-radio {
      padding-top: 0.5em;
    }

    .form-input-action {

      border-top: #9681b2 1px solid;
      padding-top: 0.5em;

      &.no-border {
        border-top:none;
      }

      width: 100%;
      clear: both;
      button {
        margin-left: 0.5em;
      }

      &.right button {
        float:right;
      }
    }

  }

  &.inline {
    .fields {
      display: flex;
      flex-direction: row;

      .form-input, .form-select, .form-datepicker, .form-wysiwyg, .form-button-group, .form-radio-group, .form-checkbox-group, > .form-checkbox, > .form-radio {
        padding-top: 0;
        width: 100%;
        flex: 1 1 auto;
      }
    }
  }

  textarea {
    resize: none;

    &.form-field:not(.fluid) {
      height: auto;
      width: auto;
    }

    &.form-field.fluid {
      min-height: 7em;
    }
  }

  .form-feedback {
    color: #713dc3
  }

  .select-toggle {
    background-color: white;
  }

}

.form-field {
  box-shadow: none;
  &:focus {
    box-shadow: 0 0 0 0.125rem #ebe7e4, 0 0 0 0.150rem #9681b2 !important;
  }
}

.form-field-toggle-read {
  border: 1px #9681b2 dashed !important;
  background-color: #f1eef2;
}

.image-upload-preview {
  margin-bottom: 0.5em;
  border: 1px dotted #8f8f9b;
  padding: 5px;
}

.datepicker-clear::after {
  background-color: white !important;
  color: #9681b2 !important;
  border-radius: 0 !important;
  height: 20px !important;
  width: 20px !important;
  font-size: 35px !important;
}

.datepicker-clear:hover::after {
  color: #4f2d7f !important;
  transition: all .3s ease;
}

.datepicker-calendar {
  font-family: GTWalsheimPro !important;
  .react-datepicker__header {
    background-color: #E8E6E3;
  }
}



