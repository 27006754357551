.news-page {

  .news-list {

    .news-title {
      color:#000000;
      font-size: 18px;
      font-weight: 500;
    }

    .news-abstract {
      text-align: justify;
      color:#333333;
      font-size: 16px;
    }

    .news-tags {
      color:#999999;
      font-size: 14px;
      text-align: right;
    }

    .news-publication-date {
      color:#999999;
      font-size: 14px;
      text-align: right;
    }
  }
}