.news-feed {
  border-style:solid;
  border-color: #C8BEAF;
  border-width: 1px 1px 4px;

  .news-list {

    .news-title {
      color:#000000;
      font-size: 18px;
      font-weight: 500;
    }

    .news-abstract {
      text-align: justify;
      color:#333333;
      font-size: 16px;
    }

    .news-publication-date {
      color:#999999;
      font-size: 12px;
      text-align: right;
    }

  }

  .all-news {
    a {
      color:#666666;
      font-size:14px;
    }
  }

}


