.app-tiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* change min size of tile height below*/
  grid-template-rows: minmax(200px, auto);
  /* change gap between items below*/
  grid-gap: 1em;
  grid-auto-flow: row;
  justify-items: stretch;
  align-items: stretch;
}

.app-tile-container {
  position:relative;
  /* change size of tile height below*/
  max-height: 200px;
  // overflow: hidden;
}

.app-title {
  color:white;
  display:flex;
  justify-content:center;
  align-items: center;
  text-align: center;
  font-size: 1.75rem;
  padding: 0 0.5em;
  background: rgba(203, 196, 188, 0.7);
  position: absolute;
  width: 100%;
  height:100%;
  top:0;
  transition: background-color 0.25s ease-in-out;
}

.app-title.active {
  background: rgba(79, 45, 127, 0.5)
}

.app-title.active:hover {
  background: rgba(79, 45, 127, 1)
}

.app-title.maintenance {
  background: rgba(245, 137, 79, 0.5)
}

.app-title.maintenance:hover {
  background: rgba(245, 137, 79, 1)
}


.app-title:hover {
  background: rgba(203, 196, 188, 1)
}

.app-background {
  object-fit: cover;
  width: 100%;
  height:100%;
  filter: grayscale(100%);
}

.info-icon {
  font-size:1.75rem;
  color:white;
  position:absolute;
  bottom:1rem;
  left:50%;
  transform:translateX(-50%);
}

@media screen and (max-width: 1200px) {
  .app-tiles{
    grid-template-columns: repeat(3, 1fr);
  }
  .app-title {
    font-size:1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .app-tiles{
    grid-template-columns: repeat(2, 1fr);
  }
  .app-title {
    font-size:1.25rem;
  }
}

@media screen and (max-width: 576px) {
  .app-tiles{
    grid-template-columns: repeat(1, 1fr);
  }
}

