.tooltip-gt {
    background:#333333;
    opacity:1;
    max-width:300px;
    font-size: 1rem;
    text-align:left;
    z-index:300;
}
.tooltip-element {
    display:inline-block;
}