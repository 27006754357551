
a.external:after {
  content:"";
  background-color: #4f2d7f;
  display:inline-flex;
  height:10px;
  width:10px;
  margin-left:4px;
  mask-image:url("./assets/img/external-link.svg");
  mask-size:cover;
  -webkit-mask-image:url("./assets/img/external-link.svg");
  -webkit-mask-size:cover;
}

.all-apps {
  a {
    color:#666666;
    font-size:14px;
  }
}

.mygt-toast {

  .Toastify__toast {
    font-family: GTWalsheimPro, Arial, sans-serif;
  }

  .Toastify__toast-theme--colored.Toastify__toast--default, .Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: #4f2d7f;
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #9bd732;
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: #f26b23;
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: #de002e;
  }
}