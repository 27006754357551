.divider {
  font-size: 1rem;
  margin: 1rem 0;
  line-height: 1;
  height: 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: rgba(0,0,0,.85);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:not(.vertical):not(.horizontal):not(.dark) {
    border-top: 1px solid rgba(34,36,38,.15);
    border-bottom: 1px solid rgba(255,255,255,.1);
  }

  &.dark:not(.vertical):not(.horizontal) {
    border-top: 1px solid rgba(34,36,38,.55);
    border-bottom: 1px solid rgba(255,255,255,.3);
  }

  &.horizontal {
    display: table;
    white-space: nowrap;
    height: auto;
    line-height: 1;
    text-align: center;
  }

  &.vertical {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 0;
    width: auto;
    height: 50%;
    line-height: 0;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &.narrow {
    margin-top:0.2em;
    margin-bottom: 1em;
  }
}