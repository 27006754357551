article {
  margin-bottom: 3em;
}

.article-header {
  .btn-icon {
    font-size: 1.2em;
  }
}

.article-introduction {
  padding-top: 2rem !important;
  padding-bottom:0.8rem !important;
}

.article-content {
  img {
    width:100%;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 101%;
}