@media (max-width: 850px) {
  .modal-dialog {
    .form.inline .fields {
      flex-direction: column;
    }
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 55rem !important;
  }
}

@media (min-width: 1024px) {
  .modal-dialog {
    max-width: 60rem !important;
  }
}