.agenda-page {

  .agenda-items-list {
    .agenda-item-title {
      color:#000000;
      font-size: 18px;
      font-weight: 500;
    }
    .agenda-item-publication-date {
        width:80px;
        line-height:1.5rem;
        color:#4f2d7f;
        .agenda-item-publication-day {
            font-size:1.5rem;
        }
    }
    .agenda-item-description {
      .agenda-item-abstract {
        text-align: justify;
        color:#333333;
        font-size: 16px;
      }
      button {
        margin-top:1rem;
      }
      .agenda-item-tags {
        color:#999999;
        font-size: 12px;
        text-align: right;
      }
    }
  }
}
