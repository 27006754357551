.navbar-top-bar-brand .navbar-top-bar-brand-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  color:white;
  cursor: default;

  .navbar-nav-icon {
    display: inherit;
    padding-top: 0.5em;
  }
}

.navbar-top-bar-toggle-btn {
  display: flex;
  float: left;
  border: none;
  background: transparent;
  border: none;
  color: #4f2d7f;
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
  text-transform: uppercase;
  cursor: pointer;
}

.nav.navbar-top-bar-nav-lang {
  li {
    button {
      display:block;
      background:transparent;
      border: none;
      color:#4f2d7f;
      text-decoration:none;
      transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
      text-transform: uppercase;
      cursor: pointer;
    }

    button.active {
      color: #333333;
      font-weight: 600;
      pointer-events: none;
    }
  }
}

.navbar-footer {
  position: absolute;
  bottom: 0;


  .navbar-footer-nav {
    padding: 0;
    margin: 0;
    list-style: none;


    li {
      padding-left: 2.5rem;

      &.row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-right: 0!important;
        margin-left: 0!important;
      }

      a {
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        position: relative;
        display: flex;
        flex: 0 1;
        align-items: center;
        min-height: 2rem;

        /** TODO: Media query **/
        font-size: 0.75rem;

        .navbar-nav-icon {
          display: flex;
          flex: 0 0 1.5rem;
          align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 1.5rem;
          font-size: 0.6875rem;
          border: 1px solid #fff;
          border-radius: 50%;
        }
      }

      .navbar-footer-nav-label {
        color: #fff;
        font-size: 0.75rem;
      }

    }
  }
}

@media (max-height: 650px) and (max-width: 1200px){
  .navbar-footer {
    display: none;
  }
}

@media (max-height: 512px) and (min-width: 1200px){
  .navbar-footer {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-top-bar-brand .navbar-top-bar-brand-label {
    height: 4.375rem;
    i {
      font-size: 1.6em;
    }
  }
}

@media (max-width: 1200px) {
  .navbar-top-bar-brand .navbar-top-bar-brand-label {
    font-size: 0.9em;
    i {
      font-size: 1.3em;
    }
  }
}