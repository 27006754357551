table {
    table-layout: auto;
    width: 100%;
    text-align: center;

    &.row-hoverable {
        tbody {
            tr:hover {
                box-shadow: 0 0 2.5rem 0 rgb(51 51 51 / 15%);
            }
        }
    }
}

tr {
    border-bottom: 1px solid #E8E6E3;
    transition:box-shadow 0.2s ease;
    height: 3em;
    text-align: center;

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }
}

th {
    span.column-name {
        color: #74767A
    }
}

td, th {
    &.right, &.details {
        text-align: right;
    }

    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }
}

.datagrid-content.rotate-headers {
    table thead th:not(:first-child) {
        vertical-align:bottom;
        height: 150px;
        .column-name-wrapper {
            transform: rotate(-45deg);
            margin-bottom: 2.5em;
            white-space: break-spaces;
        }
    }
}

.datagrid-content.fixed {
    overflow: auto;
    width: 100%;
    max-height: 100vh;
    border: 1px solid #9681b2;

    table {
        table-layout: fixed;
    }

    table thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        width: 8vw;
        background: white;
    }

    table thead th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        width: 17vw;
        background-color: #725799;
        .column-name {
            color:white;
        }
    }

    table tbody td:first-child {
        position: sticky;
        left: 0;
        background-color: #EFEFEF;
        z-index: 1;
        text-align: left;
        padding-left:5px;
    }

    td, th {
        white-space: nowrap;

        .form-checkbox {
            overflow: hidden;
        }
    }
}

.datagrid-content {
    .column-selector {
        margin-top:5px;
        margin-bottom:5px;
    }
}

.pagination.nav > li > button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1.75rem;
    height: 1.875rem;
    color: #4f2d7f;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
}

.pagination.nav > li > button.active {
    border-color: #333333;
}

.pagination.nav > li > button.disabled {
    background: #F5F3F2;
    color: #D9D8D4 !important;
    pointer-events: none;
}

.pagination.nav > li > button:not(:disabled):not(.disabled):focus {
    box-shadow: none!important;
}

.pagination.nav > li > span.disabled {
    background: #F5F3F2;
    color: #D9D8D4 !important;
    pointer-events: none;
}

// TO BE DELETED
.pagination.nav > li > a:not(:disabled):not(.disabled):focus {
    box-shadow: none!important;
}



