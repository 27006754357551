.cropper-container {
  max-width:800px;
  width:100%;
  aspect-ratio:1.5;
  margin-bottom:0.5em;
  border: 1px dotted #8f8f9b;
  padding: 5px;

  &.one-third {
    aspect-ratio:1.3333;
  }

  &.one-and-half {
    aspect-ratio: 1.5;
  }

  &.panoramic {
    aspect-ratio: 1.7777;
  }

  &.square {
    aspect-ratio: 1;
  }

  .cropper-wrapper {
    position: relative;
    height:100%;
    min-height:200px;
    margin-left:auto;
  }
}

.image-cropped-upload-preview {
  max-width:800px;
  width: 100%;
  margin-bottom:0.5em;
  border: 1px dotted #8f8f9b;
  padding: 5px;

  &.one-third {
    aspect-ratio:1.3333;
  }

  &.one-and-half {
    aspect-ratio: 1.5;
  }

  &.panoramic {
    aspect-ratio: 1.7777;
  }

  &.square {
    aspect-ratio: 1;
  }

}

