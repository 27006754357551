.tag-checkbox:not(:disabled) + .tag-container:hover {
    color: #4f2d7f !important;
    border-color: #4f2d7f !important;
}

.tag-checkbox:not(:disabled) + .tag-container:hover .tag-icon::before, .tag-checkbox:not(:disabled) + .tag-container:hover .tag-icon::after {
    /*background-color: #c23858 !important;*/
    background-color: #e43358 !important;
}

.select-toggle-text .tag-checkbox:focus + .tag-container {
    box-shadow: none;
}